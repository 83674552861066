<template>
  <div id="qschool">
    <div>
      <b-row>
        <b-col id="title">
          <p>{{ tic.short_name }} - Online Entries</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col id="logo">
          <img :src="tic.sponsor_logo" />
        </b-col>
        <b-col class="mainContent">
          <p>This is the online entry form for the {{ tic.short_name }}</p>
          <p>
            Here you can find all the details about the First Stage (Golf
            Nazionale - Terre dei Consoli GC & Parco dei Medici GC) and Final
            Stage (Golf Nazionale & Terre dei Consoli GC). For any queries,
            please write to
            <a :href="'mailto:' + tic.course_email">{{ tic.course_email }}</a>
          </p>
          <b-button v-b-toggle:my-collapse>
            Start
          </b-button>
        </b-col>
        <b-col class="mobHide"> </b-col>
      </b-row>
      <b-collapse id="my-collapse">
        <form-wizard title="" subtitle="" color="#263056">
          <tab-content
            title="Exemption Details"
            :before-change="() => validadeFormOne()"
          >
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <p>
                  Please select your exemption category TO ENTER FINAL STAGE
                </p>
                <b-form-group
                  v-for="(codex, index) in filterExemptions"
                  :key="index"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    v-model="formData.exempt"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="codex.code"
                    >{{ codex.note }}</b-form-radio
                  >
                </b-form-group>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content
            :before-change="() => validadeFormTwo()"
            v-if="formData.exempt === 'QSF' || formData.exempt === ''"
            title="Qualifying Selection"
          >
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <p v-if="validadeFormOne === false">
                  Please select an option
                </p>
                <p>
                  Please select the qualifying tournament you wish to enter
                </p>
                <b-form-group
                  label="Individual radios"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    :disabled="disabledsam"
                    v-model="formData.qualifying"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="A"
                    >{{ onesam.short_name }}</b-form-radio
                  >
                  <b-form-radio
                    :disabled="disabledsas"
                    v-model="formData.qualifying"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="B"
                    >{{ onesas.short_name }}</b-form-radio
                  >
                  <b-form-radio
                    :disabled="disabledseu"
                    v-model="formData.qualifying"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="B"
                    >{{ oneseu.short_name }}</b-form-radio
                  >
                </b-form-group>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content
            title="Player Details"
            :before-change="() => validadeFormThree()"
          >
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <p>
                  Please enter your name and date of birth and click Next.
                </p>
                <b-form-group
                  id="input-group-1"
                  label="First Name:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formData.firstName"
                    placeholder="Enter name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Family Name:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="formData.surname"
                    placeholder="Enter name"
                    required
                  ></b-form-input>
                </b-form-group>
                <label for="example-datepicker">Date of Birth</label>
                <b-form-datepicker
                  id="example-datepicker"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                  }"
                  show-decade-nav
                  no-flip
                  v-model="formData.dob"
                  class="mb-2"
                ></b-form-datepicker>
                <b-form-group
                  id="input-group-4"
                  label="Email address:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-4"
                    v-model="formData.email"
                    type="email"
                    placeholder="Please Enter"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Yardage Book" v-slot="{ ariaDescribedby }">
                  <b-form-radio
                    v-model="formData.yardage"
                    :aria-describedby="ariaDescribedby"
                    name="Yards"
                    value="yards"
                    >Yards</b-form-radio
                  >
                  <b-form-radio
                    v-model="formData.yardage"
                    :aria-describedby="ariaDescribedby"
                    name="Meters"
                    value="meters"
                    >Meters</b-form-radio
                  >
                </b-form-group>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Memeber Login">
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <p>
                  Please Login to your account
                </p>
                <b-form-group
                  id="input-group-1"
                  label="User Name:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formData.username"
                    placeholder="User Name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Password:"
                  label-for="input-2"
                >
                  <b-form-input
                    type="password"
                    id="input-2"
                    v-model="formData.password"
                    placeholder="Password"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Submit</b-button>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Personal Details">
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <b-form-input
                  type="text"
                  :placeholder="formData.firstName"
                  :disabled="selected === 1"
                  class="mt-3"
                ></b-form-input>
                <b-form-input
                  type="text"
                  :placeholder="formData.surname"
                  :disabled="selected === 1"
                  class="mt-3"
                ></b-form-input>
                <b-form-group
                  id="input-group-1"
                  label="Address:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formData.addr_line1"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Address 2:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="formData.addr_line2"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Provice/ County:"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="formData.addr_county"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-4"
                  label="Town/ City:"
                  label-for="input-4"
                >
                  <b-form-input
                    id="input-4"
                    v-model="formData.addr_city"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-5"
                  label="Postal Code"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    v-model="formData.addr_postcode"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  label="Country"
                  text-field="name"
                  placeholder="Country"
                  value-field="name"
                  v-model="formData.country"
                  :options="options"
                  >{{ options }}</b-form-select
                >
                <b-form-group
                  type="number"
                  id="input-group-6"
                  label="Telephone"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    v-model="formData.telephone"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  type="number"
                  id="input-group-7"
                  label="Mobile"
                  label-for="input-7"
                >
                  <b-form-input
                    id="input-7"
                    v-model="formData.mobile"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  label="Nationality"
                  text-field="name"
                  placeholder="Nationality"
                  value-field="name"
                  v-model="formData.nationality"
                  :options="options"
                  >{{ options }}</b-form-select
                >
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Player Status">
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <b-form-group
                  label="Playing Status"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    v-model="formData.status"
                    :aria-describedby="ariaDescribedby"
                    name="status"
                    value="Pro"
                    >Professional</b-form-radio
                  >
                  <b-form-radio
                    v-model="formData.status"
                    :aria-describedby="ariaDescribedby"
                    name="status"
                    value="Amature"
                    >Amature</b-form-radio
                  >
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Handicap Ref"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-7"
                    v-model="formData.handref"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Handicap"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="formData.handcap"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Golf Club"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-7"
                    v-model="formData.club"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Submit">
            <b-row>
              <b-col class="mobHide"> </b-col>
              <b-col>
                <b-button>Submit</b-button>
              </b-col>
              <b-col class="mobHide"> </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import axios from "axios";

export default {
  name: "Form",
  components: {
    FormWizard,
    TabContent
  },
  data() {
    return {
      formData: {
        exempt: "",
        username: "",
        password: "",
        qualifying: "",
        firstName: "",
        surname: "",
        dob: "",
        email: "",
        yardage: "",
        addr_line1: "",
        addr_line2: "",
        addr_county: "",
        addr_city: "",
        addr_postcode: "",
        country: "",
        telephone: "",
        mobile: "",
        nationality: "",
        status: "",
        handref: "",
        handcap: "",
        club: ""
      },
      selected: 1,
      tic: [],
      options: [],
      tournSeasonCode: "2020",
      mainTournCode: "QS20",
      tournStartDate: "2020-11-17",
      onesam: [],
      onesas: [],
      oneseu: [],
      tourCode: "ffg",
      nodeHost: "ocs-ffg.com",
      ticURL: "http://ffg.ocs-ffg.com/tic/",
      mosURL: "https://ocs-ffg.com/ffg-members/",
      supportMail: "alpstourgolf@ffgolf.org",
      selCountry: "FRA"
    };
  },
  validations: {
    formData: {
      exempt: { required },
      username: { required },
      password: { required },
      qualifying: { required },
      firstName: { required },
      surname: { required },
      dob: { required },
      email: { required },
      yardage: { required },
      addr_line1: { required },
      addr_line2: { required },
      addr_county: { required },
      addr_city: { required },
      addr_postcode: { required },
      country: { required },
      telephone: { required },
      mobile: { required },
      nationality: { required },
      status: { required },
      handref: { required },
      handcap: { required },
      club: { required }
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tic.exemption_notes.exemption.filter(
        exemption => !exemption.code.indexOf("QS") && exemption.note
      );
    },
    disabledsam: function() {
      var status = this.onesam.booking_open;
      var bookingOpen = false;
      if (status === "N") bookingOpen = true;
      return bookingOpen;
    },
    disabledsas: function() {
      var status = this.onesas.booking_open;
      var bookingOpen = false;
      if (status === "N") bookingOpen = true;
      return bookingOpen;
    },
    disabledseu: function() {
      var status = this.oneseu.booking_open;
      var bookingOpen = false;
      if (status === "N") bookingOpen = true;
      return bookingOpen;
    }
  },
  methods: {
    validadeFormOne() {
      if (this.formData.exempt === "") return false;
      return true;
    },
    validadeFormTwo() {
      if (this.formData.qualifying === "") return false;
      return true;
    },
    validadeFormThree() {
      if (
        this.formData.firstName === "" ||
        this.formData.surname === "" ||
        this.formData.dob === "" ||
        this.formData.email === "" ||
        this.formData.yardage === ""
      )
        return false;
      return true;
    },
    validadeFormFour() {
      if (this.formData.exempt === "") return false;
      return true;
    },
    validadeFormFive() {
      if (this.formData.exempt === "") return false;
      return true;
    },
    validadeFormSix() {
      if (this.formData.exempt === "") return false;
      return true;
    }
  },
  mounted() {
    axios
      .get(
        "https://alpstourgolf.com/wp-content/themes/alps-ocs/inc/xml2json2.php?url=" +
          this.ticURL +
          "tmticx.cgi?jsout=x~xsl=~season=" +
          this.tournSeasonCode +
          "~tourn=" +
          this.mainTournCode +
          "~exemption=y~"
      )
      .then(response => {
        this.tic = response.data;
        return axios.get("https://restcountries.eu/rest/v2/all");
      })
      .then(response => {
        this.options = response.data;
        return axios.get(
          "https://alpstourgolf.com/wp-content/themes/alps-ocs/inc/xml2json2.php?url=" +
            this.ticURL +
            "tmticx.cgi?jsout=x~xsl=~season=" +
            this.tournSeasonCode +
            "~tourn=1SAM"
        );
      })
      .then(response => {
        this.onesam = response.data;
        return axios.get(
          "https://alpstourgolf.com/wp-content/themes/alps-ocs/inc/xml2json2.php?url=" +
            this.ticURL +
            "tmticx.cgi?jsout=x~xsl=~season=" +
            this.tournSeasonCode +
            "~tourn=1SAS"
        );
      })
      .then(response => {
        this.onesas = response.data;
        return axios.get(
          "https://alpstourgolf.com/wp-content/themes/alps-ocs/inc/xml2json2.php?url=" +
            this.ticURL +
            "tmticx.cgi?jsout=x~xsl=~season=" +
            this.tournSeasonCode +
            "~tourn=1SEU"
        );
      })
      .then(response => {
        this.oneseu = response.data;
      });
  }
};
</script>

<style scoped>
.not-collapsed {
  display: none;
}
#title {
  text-align: center;
  color: white;
  background-color: #263056;
}
.mainContent {
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .mobHide {
    display: none;
  }
  #logo {
    text-align: center;
  }
  .mainContent {
    flex-basis: auto;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide {
    display: none;
  }

  #logo {
    text-align: center;
  }
}
</style>
